html {
  /* overflow: hidden;
  height: 100%; */
  background-color:black;
  /* overflow: hidden */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayshade;
  height: 100%;
  /* overflow: auto; */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.propUnitBox {
  @apply bg-zinc-900 rounded-md text-zinc-400 text-xs h-8 hover:bg-zinc-800 duration-100;
}
.propBoxDialog {
  @apply bg-black flex flex-col gap-3 text-zinc-400 fixed left-[515px] z-10 p-4 rounded-xl text-xs w-48;
}

/* On hover over the scrollbar thumb */
.customScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 12px;
}

/* The scrollbar itself */
.customScroll::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.elastic-scroll-down {
  animation: elastic-effect-down 250ms ease-out;
}
.elastic-scroll-up {
  animation: elastic-effect-up 250ms ease-out;
}

@keyframes elastic-effect-down {
  0% { transform: translateY(0); }
  50% { transform: translateY(+50px); } /* Control the extent of the bounce here */
  100% { transform: translateY(0); }
}

@keyframes elastic-effect-up {
  0% { transform: translateY(0); }
  50% { transform: translateY(-50px); } /* Control the extent of the bounce here */
  100% { transform: translateY(0); }
}

:root {
  --primary: 0 0 0; 
  --secondary: 0 0 0;
  --background: 0 0 0; 
  --foreground: 0 0 0;
  --grayshade-100: #000;
  --grayshade-200: #000;
  --grayshade-300: #000;
  --grayshade-400: #000;
  --grayshade-500: #000;
  --grayshade-600: #000;
  --grayshade-700: #000;
  --grayshade-800: #000;
  --grayshade-900: #000;
  --heading-font: 'Montserrat', sans-serif;
  --body-font: 'Roboto', sans-serif;
}